import React from "react";

export default function ImprovingWebViz(props) {
  return (
    <ul>
      <li>Problem</li>
      Disclaimer: I bet there are many tools out there that can solve you design
      or programming problems as easy as running `npm i`. This write-up is an
      attempt to lay down everything I know about visualization on web (which is
      admittedly not a lot). This article will feature an example of enterprise
      dataviz, however the techniques might be useful in designing visualization
      tools.
      <li>Data</li>
      <li>Design considerations</li>
      <li>Technical considerations</li>
      <li>Making SVG visualization</li>
      <li>Spacing points</li>
      <li>Performance cringe</li>
      <li>Moving to canvas</li>
      <li>Improving canvas density</li>
      <li>Adding voronoi grid</li>
      <li>Final remarks</li>
    </ul>
  );
}
